import AbstractModel from "./AbstractModel"
import Allergen from './Allergen'
import Translation from "./Translation"

export default class Plate extends AbstractModel {
  isVisible() {
    return !!this.attributes.mostra
  }

  name() {
    return this.findAttribute({ it: 'nome', en: 'nome_en', de: 'nome_de' })
  }

  description() {
    return this.findAttribute({ it: 'descrizione_it', en: 'descrizione_en', de: 'descrizione_de' })
  }

  smallPrice() {
    const smallPrice = Number(this.attributes.prezzo_piccolo)
    if (isNaN(smallPrice) || !smallPrice) return

    return smallPrice.toFixed(2).toLocaleString()
  }

  mediumPrice() {
    const mediumPrice = Number(this.attributes.prezzo_medio)
    if (isNaN(mediumPrice) || !mediumPrice) return

    return mediumPrice.toFixed(2).toLocaleString()
  }

  normalPrice() {
    const bigPrice = Number(this.attributes.prezzo_grande)
    if (isNaN(bigPrice) || !bigPrice) return

    return bigPrice.toFixed(2).toLocaleString()
  }

  smallPricePrefix()
  {
    if (this.emptyRelation('prefix_prezzo_piccolo')) return null
    return new Translation(this.attributes.prefix_prezzo_piccolo[0], this.lang).get()
  }

  mediumPricePrefix()
  {
    if (this.emptyRelation('prefix_prezzo_medio')) return null
    return new Translation(this.attributes.prefix_prezzo_medio[0], this.lang).get()
  }

  normalPricePrefix()
  {
    if (this.emptyRelation('prefix_prezzo_grande')) return null
    return new Translation(this.attributes.prefix_prezzo_grande[0], this.lang).get()
  }

  hasMediumPrice() {
    return this.attributes.prezzo_medio
  }

  hasBothPrices() {
    return this.attributes.prezzo_grande && this.attributes.prezzo_piccolo
  }

  hasAllergens() {
    return !this.emptyRelation('allergeni')
  }

  allergens() {
    if (!this.attributes.allergeni) return []
    return this.attributes.allergeni.map(allergene => new Allergen(allergene))
  }

  findAttribute(mappings) {
    return this.attributes[mappings[this.lang]] || this.attributes[mappings.it]
  }
}
