import AbstractModel from "./AbstractModel"
import slugify from "slugify"
import Plate from "./Plate"
import Translation from "./Translation"

export default class Course extends AbstractModel {
  name() {
    if (this.emptyRelation('traduzione')) return this.attributes.nome || null
    return new Translation(this.attributes.traduzione[0], this.lang).get()
  }

  slug() {
    return slugify(this.name() || '').toLowerCase()
  }

  notes() {
    if (this.emptyRelation('note')) return this.attributes.note || null
    return new Translation(this.attributes.note[0], this.lang).get()
  }

  plates() {
    if (this.emptyRelation('piatti')) return []
    return this.attributes.piatti.map(plate => new Plate(plate, this.lang))
  }
}
