import AbstractModel from "./AbstractModel"
import Translation from "./Translation"

export default class Allergen extends AbstractModel {
  name() {
    if (this.emptyRelation('traduzione')) return this.nome || null
    return new Translation(this.attributes.traduzione[0], this.lang).get()
  }

  icon() {
    if (this.emptyRelation('icona')) return {}
    const icon = this.attributes.icona[0]
    return icon.thumbnails.full.url
  }
}
