import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { IntlContextConsumer } from "gatsby-plugin-intl"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const iubenda = {
    it: `
      var _iub = _iub || [];
      _iub.csConfiguration = {"invalidateConsentWithoutLog":true,"consentOnContinuedBrowsing":false,"whitelabel":false,"lang":"it","siteId":1990157,"perPurposeConsent":true,"cookiePolicyId":82388793, "banner":{ "acceptButtonDisplay":true,"customizeButtonDisplay":true,"rejectButtonDisplay":true,"position":"float-bottom-center","listPurposes":true,"explicitWithdrawal":true }};
    `,
    en: `
      var _iub = _iub || [];
      _iub.csConfiguration = {"invalidateConsentWithoutLog":true,"consentOnContinuedBrowsing":false,"whitelabel":false,"lang":"en","siteId":1990157,"perPurposeConsent":true,"cookiePolicyId":50287714, "banner":{ "acceptButtonDisplay":true,"customizeButtonDisplay":true,"rejectButtonDisplay":true,"position":"float-bottom-center","listPurposes":true,"explicitWithdrawal":true }};
    `,
    de: `
      var _iub = _iub || [];
      _iub.csConfiguration = {"invalidateConsentWithoutLog":true,"consentOnContinuedBrowsing":false,"whitelabel":false,"lang":"de","siteId":1990157,"perPurposeConsent":true,"cookiePolicyId":93802166, "banner":{ "acceptButtonDisplay":true,"customizeButtonDisplay":true,"rejectButtonDisplay":true,"position":"float-bottom-center","listPurposes":true,"explicitWithdrawal":true }};
    `
  }

  return (
    <IntlContextConsumer>
      {({ language }) =>
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title}
          titleTemplate={`%s | ${site.siteMetadata.title}`}
          meta={[
            {
              name: `robots`,
              content: `noindex`
            },
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: site.siteMetadata.author,
            },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
          ].concat(meta)}
        >
          <script type="text/javascript">{iubenda[language] || iubenda.en}</script>
          <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
        </Helmet>
      }
    </IntlContextConsumer>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
