export default class AbstractModel {
  constructor({ data: attributes }, lang = 'it') {
    this.attributes = attributes || {}
    this.lang = lang
  }

  emptyRelation(attribute) {
    return !this.attributes[attribute] || !this.attributes[attribute].length
  }
}
