import React from 'react'
import { Link } from "gatsby-plugin-intl"

const NavLink = ({ to, children, onClick }) => (
  <Link to={to} className="block text-center text-xl uppercase my-10" onClick={ onClick }>
    { children }
  </Link>
)

export default NavLink
