import React from 'react'
import LangSwitch from './langSwitch'
import CoursesList from './coursesList'
import NavLink from './navLink'

import Separator from '../assets/icons/separator.svg'
import LrButton from './lrButton'
import { Link, FormattedMessage } from "gatsby-plugin-intl"

const Navigation = ({ onClick }) => (
  <nav className="lr-navigation py-16 px-6">
    <LangSwitch className="mb-16" />
    <div className="lr-navigation__courses">
      <CoursesList onClick={onClick} />
    </div>
    <Separator className="w-full h-auto my-8 inline-block" />
    <div className="lr-navigation__links">
      <NavLink to="/menu/#pane-e-coperto" onClick={onClick}>
        <FormattedMessage id="pane_e_coperto" />
      </NavLink>
      <NavLink to="/allergeni" onClick={onClick}>
        <FormattedMessage id="scheda_allergeni" />
      </NavLink>
    </div>
    {<div className="lr-navigation__wifi flex items-center justify-center">
    { process.env.GATSBY_WIFI_ACTIVE && process.env.GATSBY_WIFI_ACTIVE !== 'false' && (
      <Link to="/wifi/" className="block mb-8">
        <LrButton>
          <FormattedMessage id="accedi_wifi" />
        </LrButton>
      </Link>
    )}
    </div>}
  </nav>
)

export default Navigation
