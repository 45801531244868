import React from 'react'
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import LangButton from './langButton'

const LangSwitch = () => (
  <div className="lr-lang-switch flex items-center justify-center">
    <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <LangButton
              key={language}
              isActive={ currentLocale === language }
              language={ language }
              onClick={() => changeLocale(language)}
            />
          ))
        }
      </IntlContextConsumer>
  </div>
)

export default LangSwitch
