import React from 'react'
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import OpenMenu from '../assets/icons/menu.svg'
import CloseMenu from '../assets/icons/close-menu.svg'
import Img from "gatsby-image"

const Header = ({ children, isOpen, onClickMenu, icon }) => {
  const { formatMessage } = useIntl()

  return (
    <>
      <header className="lr-header pt-16">
        <div className="lr-header__svg print:max-w-md print:mx-auto">
          { icon && icon.childImageSharp && (<Img fluid={icon.childImageSharp.fluid} alt="Header Le Rotte" />) }
        </div>
        <div className="top-0 inset-x-0 m-4 flex justify-between items-center fixed">
          <div>
            { children }
          </div>
          <button
            aria-label={ isOpen ? formatMessage({ id: 'chiudi_menu' }) : formatMessage({ id: 'apri_menu' }) }
            className="menu-button inline-block print:hidden"
            onClick={ onClickMenu }
          >
            { isOpen ? (<CloseMenu />) : (<OpenMenu />) }
          </button>
        </div>
      </header>
    </>
  )
}

Header.defaultProps = {
  isOpen: false
}

Header.propTypes = {
  isOpen: PropTypes.bool,
  onClickMenu: PropTypes.func
}

export default Header
