import React from 'react'
import PropTypes from "prop-types"
import classNames from 'classnames'

const LangButton = ({ language, isActive, onClick }) => (
  <button
    className={classNames(
      'lr-lang__button mx-3 rounded-full w-12 h-12 border-2 hover:border-gray-300 text-xl flex items-center justify-center uppercase',
      { 'border-white': isActive }
    )}
    onClick={ onClick }
  >
    { language }
  </button>
)

LangButton.defaultProps = {
  isActive: false,
  language: ''
}

LangButton.propTypes = {
  isActive: PropTypes.bool,
  language: PropTypes.string
}

export default LangButton
