import React from 'react'
import classNames from 'classnames'

const LrButton = ({ children, onClick, type, disabled }) => (
  <button
    type={ type }
    className="min-w-56 text-sm flex p-1 items-center justify-center uppercase border-2 border-white rounded-2xl"
    onClick={ onClick }
    disabled={ disabled }
  >
    <div className={ classNames("py-4 px-6 bg-white w-full rounded-xl", {
      'opacity-100': !disabled,
      'opacity-50': disabled
    }) }>
      { children }
    </div>
  </button>
)

LrButton.defaultProps = {
  type: 'button',
  disabled: false
}

export default LrButton
