import React, { useState } from "react"
import classNames from 'classnames'

import Header from "./header"
import Navigation from "./navigation"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Layout = ({ children, isLight, footer, icon }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    window.scrollTo(0, 0);
    setMenuOpen(!menuOpen)
  }
  const closeMenu = () => setMenuOpen(false)
  const layoutClass = classNames("page-layout min-h-full flex flex-col flex-grow", {
    'page-layout--bg-dark': !isLight,
    'page-layout--bg-light': isLight
  })

  const { footerFile, navImageFile } = useStaticQuery(graphql`
  query {
    footerFile: file(relativePath: { eq: "casette.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    navImageFile: file(relativePath: { eq: "casette.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)

  return (
    <div className={layoutClass}>
      <Header onClickMenu={ toggleMenu } isOpen={menuOpen} icon={ menuOpen ? (navImageFile) : icon} />
      <main className="flex-grow max-w-4xl mx-auto w-full">
        { menuOpen ? (<Navigation onClick={closeMenu} />) : children }
      </main>
      <footer className="pb-12 print:pb-0 print:mt-8 print:max-w-lg print:mx-auto print:w-full bi-avoid bb-always">
        <Img fluid={footerFile.childImageSharp.fluid} alt="Footer Le Rotte" />
        { footer }
      </footer>
    </div>
  )
}

Layout.defaultProps = {
  isLight: false,
  icon: {}
}

export default Layout
