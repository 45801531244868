import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { IntlContextConsumer } from "gatsby-plugin-intl"

import Course from '../models/Course'
import NavLink from './navLink'


const CoursesList = ({ onClick }) => {
  const { courses } = useStaticQuery(graphql`
  query CoursesList {
    courses: allAirtable(filter: {table: {eq: "portate"}}, sort: {fields: data___id }) {
      nodes {
        data {
          nome
          traduzione {
            data {
              it
              de
              en
            }
          }
        }
      }
    }
  }
  `)

  return (
    <IntlContextConsumer>
    { ({ language }) => (
      <div className="courses-list">
        { courses.nodes.map((portata, index) => {
          const course = new Course(portata, language)
          return (
            <NavLink key={index} to={`/menu/#${course.slug()}`} onClick={ onClick }>
              <span>{ course.name() }</span>
            </NavLink>
          )
        }) }
      </div>
    ) }
    </IntlContextConsumer>
  )
}

export default CoursesList
